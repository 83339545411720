import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const CustomLink: React.FC<Props> = ({ to, children, onClick }) => {
  const isExternal = to.startsWith('http://') || to.startsWith('https://');
  const commonProps = onClick ? { onClick } : {};

  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...commonProps}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...commonProps}>
      {children}
    </Link>
  );
};

export default CustomLink;
