import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import BlankLayout from '../BlankLayout';
import { useFirestore } from '../../hooks/useFirestore';
import { useUser } from '../../hooks/useUser';
import { Link } from 'react-router-dom';
import useConfig from '../../stores/config';
import CustomLink from './CustomLink';

import styles from './MyApps.module.scss';
import { Loan } from '../../schema';
import {
  DEFAULT_SOURCE_ID,
  isProduction,
  PROD_DOC_UPLOAD_URL,
  QA_DOC_UPLOAD_URL,
} from '../../constants';
import { pushToDataLayer, callGetLoanDocumentsGCPFunction } from '../../utils';

type Props = {};

const MyApps: React.FC<Props> = observer(function MyApps() {
  const [apps, setApps] = useState<Array<Loan> | null>(null);
  const user = useUser();
  const firestore = useFirestore();
  const config = useConfig();

  const onClickContinue = () => {
    localStorage.removeItem('loanId');
    pushToDataLayer('link_click', {
      action_type: 'Continue your Boat RV loan',
      action_label: 'internal_link',
    });
  };

  const onClickViewMyDocs = () => {
    pushToDataLayer('link_click', {
      action_type: 'View my Documents',
      action_label: 'salesforce docs',
    });
  };

  useEffect(() => {
    if (!user || !config) return;
    const query = firestore
      .collection('loans')
      .where('userId', '==', user?.uid)
      .orderBy('createdAt', 'desc');
    query.get().then(async (snapshot) => {
      const results: Array<Loan> = [];
      const updatePromises: Promise<void>[] = [];

      const validSources = config?.getValidSourcesFromLocation(window.location.href) || [];
      const validSourceIds = validSources.map((source) => source.id);

      if (validSourceIds.length === 0) {
        validSourceIds.push(DEFAULT_SOURCE_ID);
      }

      snapshot.forEach((doc) => {
        const loan = doc.data() as Loan;
        loan.id = doc.id;

        if (validSourceIds.includes(loan.source)) {
          results.push(loan);
          if (loan.workflow === 'submitted' && !loan.requestedDocuments) {
            const updatePromise = callGetLoanDocumentsGCPFunction(loan.id)
              .then(async (response) => {
                if (
                  Array.isArray(response?.requestedDocuments) &&
                  response.requestedDocuments.length > 0
                ) {
                  loan.requestedDocuments = true;
                  loan.docUploadUrl = `${
                    isProduction() ? PROD_DOC_UPLOAD_URL : QA_DOC_UPLOAD_URL
                  }/?appId=${response.id}&docGroup=Application%2FCredit%20Requests`;
                  await firestore.collection('loans').doc(loan.id).update({
                    requestedDocuments: true,
                    docUploadUrl: loan.docUploadUrl,
                  });
                }
              })
              .catch((error) => {
                console.error('Error calling getLoanDocuments:', error);
              });
            updatePromises.push(updatePromise);
          }
        }
      });

      const settledResults = await Promise.allSettled(updatePromises);
      settledResults.forEach((result, index) => {
        if (result.status === 'rejected') {
          console.error(`Promise ${index} failed:`, result.reason);
        }
      });

      setApps(results);
    });
  }, [firestore, user, config]);

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: '“my applications',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  let content = null;
  if (apps === null || user === null) {
    content = <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />;
  } else if (!apps.length) {
    content = (
      <p style={{ textAlign: 'center' }}>
        None found! <Link to="/">Click here to start a new application.</Link>
      </p>
    );
  } else {
    content = (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Application ID</th>
            <th>Make</th>
            <th>Model</th>
            <th>Status</th>
            <th>Last Modified</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {apps.map((loan) => {
            const step = loan.workflow === 'submitted' ? 'success' : loan.currentStep;
            const toLink = loan?.requestedDocuments
              ? loan?.docUploadUrl ?? '/'
              : `/${loan.id}/${step || 'details'}`;
            const linkLabel = loan?.requestedDocuments
              ? 'View my documents'
              : `Continue your ${loan.purpose} loan`;
            const onClick = loan?.requestedDocuments ? onClickViewMyDocs : onClickContinue;

            return (
              <tr key={loan.id}>
                <td data-label="Application ID">{loan.id}</td>
                <td data-label="Make">{loan?.collateral?.manufacturer || ''}</td>
                <td data-label="Model">{loan?.collateral?.modelNumber || ''}</td>
                <td data-label="Status">{loan.workflow || 'draft'}</td>
                <td data-label="Last Modified">
                  {loan.updatedAt ? new Date(loan.updatedAt).toLocaleString() : ''}
                </td>
                <td data-label="Action" key={loan.id}>
                  <CustomLink to={toLink} onClick={onClick}>
                    {linkLabel}
                  </CustomLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return <BlankLayout title="Your Applications">{content}</BlankLayout>;
});

export default MyApps;
