import React from 'react';
import { Footer } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../../stores/store';
import {
  fetchRepidFax,
  fetchRepidPhone,
  renderFaxNumber,
  renderMainNumber,
  renderNumber,
} from './PhoneNumbers';

import styles from './AppFooter.module.scss';
import LockIcon from '../../../icons/LockIcon';
import { Loan, LoanPurpose, Source } from '../../../schema';
import { generateUrlParams } from '../../../utils';
import useConfig from '../../../stores/config';
import getSource from '../../../utils/getSource';
import {
  OPERATION_HOURS_DEFAULT,
  GOODSAM_SOURCE_ID,
  BOAT_TRADER_SOURCE_ID,
  DEFAULT_LOGO_URL,
} from '../../../constants';
import { useTheme } from '../../../hooks/useTheme';
import { useParams } from 'react-router-dom';

const links = {
  [LoanPurpose.aircraft]: 'https://www.tridentfunding.com/faq-aircraft/',
  [LoanPurpose.rv]: 'https://www.tridentfunding.com/faq-rv/',
  [LoanPurpose.boat]: 'https://www.tridentfunding.com/faq-marine/',
};

function setGoodSamNumber(
  purpose: LoanPurpose | undefined,
  source: Source | null
): string | undefined {
  let goodSamNumber;

  if (!purpose) {
    goodSamNumber = source?.phone;
  } else {
    goodSamNumber = purpose === LoanPurpose.rv ? source?.phone : source?.marinePhone;
  }

  return goodSamNumber;
}

type Props = {
  isNewFunnel?: boolean;
};

export const AppFooter: React.FC<Props> = observer(function AppFooter({ isNewFunnel = false }) {
  const config = useConfig();
  const loanStore = useLoan();
  const { loan } = loanStore;
  const urlQueryParams = generateUrlParams(loan);

  const { source } = getSource(config, loan as Loan);
  const isGoodSam = source?.id === GOODSAM_SOURCE_ID;

  const mainPhone =
    (isGoodSam && setGoodSamNumber(loan?.purpose, source)) ||
    fetchRepidPhone(loan?.repCode, config?.salesReps);

  let operationHours: string;

  if (loan?.purpose === LoanPurpose.rv) {
    operationHours = source?.operationHours || OPERATION_HOURS_DEFAULT;
  } else {
    operationHours = source?.operationHoursMarine || OPERATION_HOURS_DEFAULT;
  }

  let faqLink = links[LoanPurpose.rv];
  if (loan && loan.purpose) {
    faqLink = links[loan.purpose];
  }

  const year = new Date().getFullYear();

  let urlParams: any = {};

  try {
    // this errors if there's no match
    // eslint-disable-next-line react-hooks/rules-of-hooks
    urlParams = useParams();
  } catch (e) {
    // don't need this
  }

  const theme = useTheme(loanStore, config, urlParams.loanId == null);

  return (
    <Footer className={styles.footer}>
      <div className={styles.footerWrap}>
        {source?.id === BOAT_TRADER_SOURCE_ID && (
          <div className={styles.logoWrap}>
            <img
              id="footer-logo"
              src={
                isNewFunnel
                  ? '/static/trident/boattrader-trident-logo-blue.png'
                  : theme?.logo || DEFAULT_LOGO_URL
              }
              alt="logo"
            />
          </div>
        )}
        <div className={styles.columnsWrap}>
          <div className={styles.left}>
            <p>
              <span className={styles.secured}>
                <LockIcon width="0.8em" height="0.8em" fill="white" />
                Secured Connection
              </span>
              <span className={styles.copy} id="footer-copy">
                © {year} {source?.displayName || 'Trident Funding LLC'}
              </span>
            </p>
            <p>
              <a
                href={`https://www.tridentfunding.com/about-us/${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="about-us"
              >
                About
              </a>
              <a
                href={`${faqLink}${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="faq"
              >
                FAQ
              </a>
              <a
                href="https://www.tridentfunding.com/wp-content/uploads/2022/03/Trident-Funding-Privacy-Policy-Boats-Group-12-2021.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Consumer Privacy Policy
              </a>
              <a
                href={`https://www.tridentfunding.com/privacy-policy/${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="privacy-policy"
              >
                Privacy Notice
              </a>
              <a
                href={`https://www.tridentfunding.com/terms-of-use/${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="terms-of-use"
              >
                Terms of Use
              </a>
            </p>
          </div>
          <div className={styles.space} />
          <div className={styles.right}>
            <div className={styles.needHelpTop}>
              <span className={styles.needHelp}>Need Help?</span>
              <div className={styles.phoneContainer}>
                <div className={styles.phones}>
                  {renderMainNumber(loan?.purpose, source, mainPhone)}
                  {renderNumber({ number: source?.secondaryPhone })}
                  {renderNumber({ number: source?.ttyNumber, label: 'TTY' })}
                  {renderFaxNumber(
                    loan?.purpose,
                    source,
                    fetchRepidFax(loan?.repCode, config?.salesReps, config?.offices)
                  )}
                </div>
              </div>
            </div>
            <p>{operationHours}</p>
          </div>
        </div>
      </div>
    </Footer>
  );
});
