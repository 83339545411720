import React from 'react';
import { Col } from 'antd';
import { ReactComponent as PrivacyPolicyShield } from '../../icons/privacy_icon.svg';

const column12 = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };
const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };

const PrivacyPolicyLink = ({ wideColumn = false, isCoBorrower = false }) => {
  const columnWidth = isCoBorrower ? { ...column12 } : centerSmall;
  const centerOffset =
    (wideColumn && { ...centerSmallOffset, md: 8, lg: 8, xl: 8 }) || centerSmallOffset;

  return (
    <>
      {!isCoBorrower && <Col {...centerOffset} />}
      <Col {...columnWidth}>
        <a
          href="https://www.tridentfunding.com/privacy-policy/"
          target="_blank"
          className="privacyPolicyLink"
          rel="noreferrer"
        >
          <PrivacyPolicyShield />
          &nbsp;Privacy Notice
        </a>
      </Col>
    </>
  );
};

export default PrivacyPolicyLink;
